//Form inputs
const name = document.getElementById("name");
const email = document.getElementById("email");
const linkedin = document.getElementById("linkedin");
const subject = document.getElementById("subject");
const message = document.getElementById("message");
const contactForm = document.querySelector(".contactForm");

//Feedback popup
const formPopup = document.getElementById("formPopup");
const infoPopupMsg = document.getElementById("infoPopupMsg");
const popupDuration = 2500;
const successMsg = "Message sent!";
const failureMsg = "Something went wrong.";

const initContantForm = () => {
  if (contactForm)
    contactForm.addEventListener("submit", async (e) => {
      e.preventDefault();

      let _name, _email, _linkedin, _subject, _message;

      if (name) _name = name.value;
      if (email) _email = email.value;
      if (linkedin) _linkedin = linkedin.value;
      if (subject) _subject = subject.value;
      if (message) _message = message.value;

      const formData = {
        name: _name,
        email: _email,
        linkedin: _linkedin,
        subject: _subject,
        message: _message,
      };

      try {
        console.log(formData);
        const rawResponse = await fetch("/contactFormAPI", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const content = await rawResponse.json();
        if (content.status == "success") handleFormSuccess();
        else handleFormError();
      } catch (err) {
        handleFormError();
        console.log(err);
      }
    });
};
const handleFormError = () => {
  //console.log("Something went wrong");
  showPopup({ success: false });
};
const handleFormSuccess = () => {
  //console.log("The Form has been submitted succesfully.");
  if (name) name.value = "";
  if (email) email.value = "";
  if (linkedin) linkedin.value = "";
  if (subject) subject.value = "";
  if (message) message.value = "";
  showPopup({ success: true });
};

const showPopup = ({ success = true }) => {
  if (infoPopupMsg) {
    if (success) infoPopupMsg.innerText = successMsg;
    else infoPopupMsg.innerText = failureMsg;
  }

  if (formPopup) {
    formPopup.style.visibility = "visible";
    formPopup.addEventListener(
      "click",
      () => (formPopup.style.visibility = "hidden")
    );
    setTimeout(() => (formPopup.style.visibility = "hidden"), popupDuration);
  }
};

const pass = document.getElementById("passForm");
const pwd = document.getElementById("pwd");
const pwdPopup = document.getElementById("pwdPopup");
const pwdPopupMsg = document.getElementById("pwdPopupMsg");

const initPassword = () => {
  if (pass)
    pass.addEventListener("submit", async (e) => {
      e.preventDefault();

      let _pwd;
      if (pwd) _pwd = pwd.value;

      const formData = {
        pwd: _pwd,
      };

      showPassPopup({});
      if (pwd) pwd.value = "";

      // try {
      //   console.log(formData);
      //   const rawResponse = await fetch("/contactFormAPI", {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(formData),
      //   });
      //   const content = await rawResponse.json();
      //   if (content.status == "success") handleFormSuccess();
      //   else handleFormError();
      // } catch (err) {
      //   handleFormError();
      //   console.log(err);
      // }
    });
};

const showPassPopup = ({ success = true }) => {
  if (pwdPopupMsg) {
    if (success) pwdPopupMsg.innerText = "Wrong password, please try again";
    // successMsg;
    else pwdPopupMsg.innerText = failureMsg;
  }

  if (pwdPopup) {
    pwdPopup.style.visibility = "visible";
    pwdPopup.addEventListener(
      "click",
      () => (pwdPopup.style.visibility = "hidden")
    );
    setTimeout(() => (pwdPopup.style.visibility = "hidden"), popupDuration);
  }
};

export { initContantForm, initPassword };
