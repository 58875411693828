const aboutMode = (width) => {
  const teamContainer = document.querySelector("#teamContainer");

  if (width < 1000) {
    if (teamContainer) teamContainer.style.flexDirection = "column";
  } else if (width > 1000) {
    if (teamContainer) teamContainer.style.flexDirection = "row";
  }
};

export { aboutMode };
