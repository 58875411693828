const menuMode = (width) => {
  const hMenu = document.querySelector("#burgerMenu");
  const wMenu = document.querySelector("#wideMenu");
  const fMenu = document.querySelector("#footerMenu");

  if (width < 1000) {
    wMenu.style.display = "none";
    if (fMenu) fMenu.style.display = "none";
    hMenu.style.display = "block";
  } else if (width > 1000) {
    wMenu.style.display = "flex";
    if (fMenu) fMenu.style.display = "flex";
    hMenu.style.display = "none";
  }
};
const footerMode = (width) => {
  const social = document.querySelector(".socialIcons");
  const icons = document.querySelector(".icons");
  if (social) {
    if (width < 600) {
      social.style.flexDirection = "column";
      icons.style.position = "relative";
      icons.style.margin = 0;
    } else if (width > 600) {
      social.style.flexDirection = "row";
      icons.style.position = "absolute";
      icons.style.margin = "10%";
    }
  }
};

let mobileMenuState = false;
const toggleMobileMenu = () => {
  const mobileMenu = document.querySelector(".mobileMenu");
  if (!mobileMenuState) {
    mobileMenuState = true;
    mobileMenu.style.transform = "translateX(0%)";
  } else {
    mobileMenuState = false;
    mobileMenu.style.transform = "translateX(100%)";
  }
};

const hamburgerSetup = () => {
  const hamburger = document.querySelector(".hamburger");
  if (hamburger)
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");
      toggleMobileMenu();
    });
};

export { menuMode, footerMode, hamburgerSetup };
