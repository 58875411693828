//import { gsap } from "gsap/all.js";
import { menuMode, footerMode, hamburgerSetup } from "./navs";
import { landingMode } from "./homePage";
import { initContantForm, initPassword  } from "./contactForm";
import { aboutMode } from "./about";

const setup = () => {
  window.addEventListener("resize", handleWindowResize);
  handleWindowResize();
  hamburgerSetup();
  initContantForm();
  initPassword();
};

let response = {
  //put the responsive functions here
  nav: [footerMode, menuMode],
  home: [landingMode],
  about: [aboutMode],
};

const handleWindowResize = () => {
  const width = window.innerWidth;
  console.log(width);
  response.nav.forEach((check) => check(width));
  response.home.forEach((check) => check(width));
  response.about.forEach((check) => check(width));
};

setup();
//export { gsap };
